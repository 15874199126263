import React from "react"
import "../css/main.css"
import "../css/bloghome.css"
import { Link } from "gatsby"

const bloghome = () => {
  return (
    <section className="boundry noSelect">
      <div className="blog">
        <h3 className="blog-heading">Featured Blogs</h3>
        <div class="full-box">
          <h3>"Tech Programmimg blog"</h3>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://arpitsomani.blogspot.com/2020/09/the-evergreen-python.html"
          >
            <div className="box6">
              <h4>How to Create AWS EC2 Instance Cloud?</h4>
            </div>
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://arpitsomani.blogspot.com/2020/09/the-evergreen-python.html"
          >
            <div className="box6">
              <h4>The Evergreen: Python</h4>
            </div>
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://arpitsomani.blogspot.com/2020/08/the-straight-path-to-web-development.html"
          >
            <div className="box6">
              <h4>The Straight Path to Web Development!</h4>
            </div>
          </a>
        </div>
        <div className="btn-poson">
          <Link to="/blog/">
            <button className="btnggo">
              <span>More Blog Posts</span>
            </button>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default bloghome
