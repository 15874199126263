import React from "react"
import "../css/main.css"
import "../css/projecthome.css"
import { Link } from "gatsby"

const projecthome = () => {
  return (
    <section className="bo noSelect">
      <div className="proj">
        <h3 className="project-heading">Featured Projects</h3>

        <div className="python-proj">
          <h3>"In Field of Machine Learning"</h3>
          <Link>
            <div className="box5 box61">
              <h4>Salary Prediction</h4>
              <div className="short-description">
                <p>
                  short description for website like Full Fleged website etc.
                </p>
              </div>

              <div className="btn-posit">
                <a
                  href="https://github.com/arpitsomani8/Salary-Prediction-Web-App"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btngggg">
                    <span>Github Code</span>
                  </button>
                </a>
                <a
                  href="https://salarypredictby-arpitsomani.herokuapp.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btngggg">
                    <span>View Live</span>
                  </button>
                </a>
              </div>
              <div className="btn-positi">
                <Link to="ml_proj1/">
                  <button className="btnggg">
                    <span>More Detail</span>
                  </button>
                </Link>
              </div>
            </div>
          </Link>
          <Link>
            <div className="box5 box62">
              <h4>Predict Car Price</h4>
              <div className="short-description">
                <p>
                  short description for website like Full Fleged website etc.
                </p>
              </div>

              <div className="btn-posit">
                <a
                  href="https://github.com/arpitsomani8/Car-Price-Prediction-Web-App"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btngggg">
                    <span>Github Code</span>
                  </button>
                </a>
                <a
                  href="https://car-price-prediction-by-arpit.herokuapp.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btngggg">
                    <span>View Live</span>
                  </button>
                </a>
              </div>
              <div className="btn-positi">
                <Link to="/ml_proj2/">
                  <button className="btnggg">
                    <span>More Detail</span>
                  </button>
                </Link>
              </div>
            </div>
          </Link>
        </div>

        <div className="python-proj">
          <h3>"In Field of Python"</h3>
          <Link>
            <div className="box5 box51">
              <h4> Weather App</h4>
              <div className="short-description">
                <p>
                  short description for website like Full Fleged website etc.
                </p>
              </div>
              <div className="btn-posit">
                <a
                  href="https://github.com/arpitsomani8/Django-Weather-App"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btngggg">
                    <span>Github Code</span>
                  </button>
                </a>
                <a
                  href="https://arpitsomani.herokuapp.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btngggg">
                    <span>View Live</span>
                  </button>
                </a>
              </div>
              <div className="btn-positi">
                <Link to="/py_proj34/">
                  <button className="btnggg">
                    <span>More Detail</span>
                  </button>
                </Link>
              </div>
            </div>
          </Link>

          <Link>
            <div className="box5">
              <h4>Egg Catcher Game</h4>
              <div className="short-description">
                <p>
                  short description for website like Full Fleged website etc.
                </p>
              </div>

              <div className="btn-position">
                <a
                  href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Egg%20Catcher%20Game"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btng">
                    <span>Github Code</span>
                  </button>
                </a>
                <Link to="/py_proj1/">
                  <button className="btng">
                    <span>More Detail</span>
                  </button>
                </Link>
              </div>
            </div>
          </Link>

        </div>

        <div className="python-proj">
          <h3>"In Field of Web Development"</h3>
          <Link>
            <div className="box5 box52">
              <h4>Ristotante Con fusion</h4>
              <div className="short-description">
                <p>
                  short description for website like Full Fleged website etc.
                </p>
              </div>

              <div className="btn-posit">
                <a
                  href="https://github.com/arpitsomani8/conFusion-React-Firebase"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btngggg">
                    <span>Github Code</span>
                  </button>
                </a>
                <a
                  href="https://ristorante-con-fusion-food.netlify.app/home"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btngggg">
                    <span>View Live</span>
                  </button>
                </a>
              </div>
              <div className="btn-positi">
                <Link to="/web_proj1/">
                  <button className="btnggg">
                    <span>More Detail</span>
                  </button>
                </Link>
              </div>
            </div>
          </Link>
          <Link>
            <div className="box5 box53">
              <h4>Coffee Shop</h4>
              <div className="short-description">
                <p>
                  short description for website like Full Fleged website etc.
                </p>
              </div>

              <div className="btn-posit">
                <a
                  href="https://github.com/arpitsomani8/Coffee-Website-Bootstrap"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btngggg">
                    <span>Github Code</span>
                  </button>
                </a>
                <a
                  href="https://arpitsomani8.github.io/Coffee-Website-Bootstrap/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btngggg">
                    <span>View Live</span>
                  </button>
                </a>
              </div>
              <div className="btn-positi">
                <Link to="/web_proj3/">
                  <button className="btnggg">
                    <span>More Detail</span>
                  </button>
                </Link>
              </div>
            </div>
          </Link>
        </div>

        <div className="btn-position">
          <Link to="/projects/">
            <button className="btngg">
              <span>Project Ocean</span>
            </button>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default projecthome
