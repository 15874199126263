import React from "react"
import Hero from "../components/Hero"
import Abouthome from "../components/abouthome"
import Bloghome from "../components/bloghome"
import Projecthome from "../components/projecthome"
import Contacthome from "../components/contacthome"
import Layout from "../components/Layout"
import Particle from "../components/Particle"
import SEO from "../components/SEO"

export default function Home() {
  return (
    <Layout>
      <SEO title="Home" description="This is home page for this website." />
      <Particle></Particle>
      <Hero />
      <Abouthome />
      <Projecthome />
      <Bloghome />
      <Contacthome />
    </Layout>
  )
}
