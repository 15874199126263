import React from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import Typical from "react-typical"
import "../css/main.css"
import "../css/hero.css"

const query = graphql`
  {
    file(relativePath: { eq: "hero-img.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Hero = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)

  return (
    <header className="hero noSelect">
      <div className="section-center hero-center">
        <div className="intro">
          <h3 className="hello">
            Hi !
            <span role="img" aria-label="Happy Hello">
              😃
            </span>
          </h3>
          <h3 className="myself">Myself</h3>
          <h2 className="name">Arpit Somani</h2>
          <p>
            I'm a{" "}
            <Typical
              className="typer"
              loop={Infinity}
              wrapper="b"
              steps={[
                "Python Programmer",
                1000,
                "Web Developer",
                1000,
                "ML/AI/DL Enthusiast",
                1000,
              ]}
            />
          </p>
        </div>
        <div className="arpit-img">
          <Image fluid={fluid} className="hero-img" />
        </div>
      </div>
    </header>
  )
}

export default Hero
