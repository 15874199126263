import React from "react"
import "../css/main.css"
import "../css/contacthome.css"
import { Button } from "reactstrap"
import { Link } from "gatsby"

const contacthome = () => {
  return (
    <>
      <section className="cont noSelect">
        <div className="contact">
          <h2>Check-out My Resume</h2>
          <div className="box3">
            <h3>Here...</h3>
            <div className="bnt">
              <Link to="/resume/">
                <Button className="btn">
                  <span>Resume</span>
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="cont noSelect">
        <div className="contact">
          <h2>Want to Contact ?</h2>
          <div className="box3">
            <h3>Go This Way...</h3>

            <div className="bnt">
              <Link to="/contact/">
                <Button className="btn">
                  <span>Contact</span>
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default contacthome
