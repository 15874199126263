import React from "react"
import "../css/main.css"
import "../css/abouthome.css"
import { Button } from "reactstrap"
import { Link } from "gatsby"

const abouthome = () => {
  return (
    <section className="line noSelect">
      <div className="box">
        <h3 className="about-heading">At a Glance</h3>
        <p className="about-description">
          Well, I am a engineering student pursuing B.tech degree in computer
          science field.
          <br />
          <br />
          Sprightly looking for internship/opportunity to enhance my skill
          set.<br /><br /> As well as learn new skills to increase real-world work experience.
        </p>
        <p className="about-description noSelect">
          Take a look on my Cool projects, which I have bulit so far =>{" "}
          <Link to="/projects/" className="q-box noSelect">
            "Project Ocean"
          </Link>
          ...
          <br />
          <br />I also write a blog, you will find it =>{" "}
          <Link to="/blog/" className="q-box noSelect">
            "Here"
          </Link>
          ...
        </p>

        <Link to="/about/">
          <Button className="btn">
            <span>Know Me More</span>
          </Button>
        </Link>
      </div>
    </section>
  )
}

export default abouthome
